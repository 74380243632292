import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {CamService} from '../services/CamService';
import ToastService from "../services/ToastService";
import {connect} from "react-redux";
import * as PictureService from "../services/PictureService";
import IconButton from "@material-ui/core/IconButton";
import CameraIcon from "@material-ui/icons/Camera";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {endStream} from '../services/MediaStreamService';
import {pictureSave} from "../redux/actions/applicationData";

function Camera(props) {

    const {
        order,
        pictureSave,
    } = props;

    // const [cam, setCam] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [captured, setCaptured] = useState(false);
    const canvasElement = document.createElement('canvas');
    const camElement = useRef(null)
    const [cam, setCam] = useState(null);

    useEffect(() => {
        endStream();
        if (camElement && order) {
            const newCam = new CamService(camElement.current, canvasElement);
            newCam.setup().catch((e) => {
                ToastService.error('Error getting access to the camera.');
            });
            setCam(newCam);
        } else {
            endStream();
        }
        return (() => {
            endStream();
        });
    }, [camElement, order]);


    const captureImage = async () => {
        const capturedData = cam.takeBase64Photo({type: 'jpeg', quality: 0.8});
        setCaptured(true);
        setCapturedImage(capturedData.base64);
    }

    const discardImage = () => {
        setCaptured(false);
        setCapturedImage(null);
    }

    const saveImage = () => {
        // PictureService.addLocalPicture(capturedImage);
        pictureSave({data: capturedImage});
        // ToastService.success('Image saved locally');
        discardImage();
    }

    const imageDisplay = capturedImage ?
        <div style={{
            backgroundImage: `url(${capturedImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            width: '100%',
            height: '100%'
        }}/>
        :
        null;

    const buttons = captured ?
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <IconButton onClick={discardImage} color="secondary" aria-label="Discard picture" style={{marginRight: 30}}>
                <CancelIcon style={{fontSize: 72, color: 'red'}}/>
            </IconButton>
            <IconButton onClick={saveImage} color="primary" aria-label="Save picture" style={{marginLeft: 30}}>
                <SaveAltIcon style={{fontSize: 72}}/>
            </IconButton>
        </div> :
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <IconButton onClick={captureImage} variant={''} color="primary" aria-label="Take picture">
                <CameraIcon style={{fontSize: 90}}/>
            </IconButton>
        </div>

    const videoWrapperRef = useRef(null);

    const videoPlayerDefaultStyle = {
        objectFit: 'contain',
        height: '0',
        width: '0',
    };

    const [videoPlayerStyle, setVideoPlayerStyle] = useState(videoPlayerDefaultStyle);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });

            setVideoPlayerStyle(videoPlayerDefaultStyle);

            if (!videoWrapperRef.current) {
                return;
            }

            const newVideoPlayerStyle = {
                height: videoWrapperRef.current.clientHeight,
                width: videoWrapperRef.current.clientWidth,
            };

            setVideoPlayerStyle(newVideoPlayerStyle);
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [videoWrapperRef, order]);

    if (order)
        return (
            <div
                style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1',
                    width: '100%',
                    height: '100%'
                }}>
                    <div ref={videoWrapperRef} style={{
                        display: captured ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                        <video ref={camElement} autoPlay playsInline muted style={videoPlayerStyle}/>
                    </div>
                    <div style={{flex: 1, width: '100%', height: '100%', display: captured ? 'block' : 'flex'}}>
                        {imageDisplay}
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                    opacity: .5
                }}>
                    {buttons}
                </div>
            </div>
        );

    return (
        <div>No order selected</div>
    );
}

export default connect(({applicationData}) => ({
    order: applicationData.pictureModeData.order,
}), {pictureSave})(Camera);