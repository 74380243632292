import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CameraIcon from '@material-ui/icons/Camera';
import DarkModeIcon from '@material-ui/icons/NightsStay';
import LightModeIcon from '@material-ui/icons/WbSunny';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew';

import "./HeaderNavNavigationItems.css";
import {Button, CircularProgress, Switch, Typography, withWidth} from "@material-ui/core";
import {connect} from "react-redux";
import {logoutRequest, pictureModeRequest, receiveModeRequest} from "../../redux/actions/applicationData";
import Hidden from "@material-ui/core/Hidden";
import * as ThemeService from "../../services/ThemeService";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: theme.spacing(1),
    },
    iconLeft: {
        marginRight: theme.spacing(1),
    },
    loggedUserContainer: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(3),
        textAlign: 'right',
    },
}));

function HeaderNavNavigationItems(props) {
    const {
        logoutRequest,
        receiveModeRequest,
        pictureModeRequest,
        user,
        firebaseSignedIn,
        paired,
        width
    } = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleLogout() {
        logoutRequest();
    }

    function handleReceiveModeClicked() {
        receiveModeRequest();
    }

    function handlePictureModeClicked() {
        pictureModeRequest();
    }

    const currentLightMode = ThemeService.getLightMode();

    function handleLightModeToggle() {
        ThemeService.setLightMode(currentLightMode === 'light' ? 'dark' : 'light');
        window.location.reload(false);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            {/*<MenuItem onClick={handleMenuClose}>*/}
            {/*    <BuildIcon className={"menuIcon"}/>*/}
            {/*    Edit device*/}
            {/*</MenuItem>*/}
            {/*<Divider/>*/}
            <MenuItem>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <DarkModeIcon style={{color: currentLightMode === 'dark' ? 'white' : '#666666'}}/>
                    <Switch checked={currentLightMode === 'light'} onChange={() => handleLightModeToggle()} name="lightMode"/>
                    <LightModeIcon style={{color: '#FDB813'}}/>
                </div>
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
                <PowerSettingsIcon color={"secondary"} className={"menuIcon"}/>
                Logout
            </MenuItem>
        </Menu>
    );

    const displayName = user ? (user.firstName + ' ' + user.lastName) : '';
    const xsBtnStyle = {
        paddingLeft: 4,
    }

    let btnStyle = {};
    if (width === 'xs') {
        btnStyle = {
            ...btnStyle,
            ...xsBtnStyle,
        }
    }

    let userMenuStyle = {};
    const smUserMenuStyle = {
        flex: 1,
    }

    if (width === 'xs' || width === 'sm') {
        userMenuStyle = {
            ...smUserMenuStyle,
        }
    }

    const renderFirebaseLoader = () => {
        if (firebaseSignedIn) {
            return null;
        }

        return (
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: 24 }}>
                <CircularProgress size={16} color='secondary' style={{marginRight: 12, marginBottom: 2}}/>
                <Typography variant={'overline'} align={'center'}>Initializing...</Typography>
            </Box>
        )
    }

    const renderPaperlessAdvancedFeatures = () => {
        const features = user?.tenant?.subscription?.features;

        if (!features?.length) {
            return null;
        }

        const paperlessAdvancedEnabled = features.find((it) => {
            return it === 'paperlessAdvanced';
        });

        if (!paperlessAdvancedEnabled) {
            return null;
        }

        return (
            <Tooltip title="Enter picture mode"
                     aria-label="Enter picture mode">
                <Button
                    style={{...btnStyle}}
                    variant={width === 'xs' ? 'contained' : 'outlined'}
                    color='primary'
                    onClick={() => handlePictureModeClicked()}
                    endIcon={<CameraIcon/>}>
                    <Hidden xsDown>Picture mode</Hidden>
                </Button>
            </Tooltip>
        )
    }

    return (
        <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            { renderFirebaseLoader() }
            <div style={{display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'flex-end'}}>
                { renderPaperlessAdvancedFeatures() }
                {paired &&
                <Tooltip title="Enter signature receive mode"
                         aria-label="Enter signature receive mode">
                    <Button
                        style={{marginLeft: 12, ...btnStyle}}
                        variant={width === 'xs' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => handleReceiveModeClicked()}
                        endIcon={<CloudUploadIcon/>}>
                        <Hidden xsDown>Signature receive mode</Hidden>
                    </Button>
                </Tooltip>}
                <section style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ...userMenuStyle}}>
                    <div className={classes.loggedUserContainer}>
                        {displayName}
                    </div>
                    <Tooltip title={'Logged in as: ' + displayName}
                             aria-label={'Logged in as: ' + displayName}>
                        <IconButton
                            edge="end"
                            aria-label="Account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}>
                            <AccountCircleIcon/>
                        </IconButton>
                    </Tooltip>
                    {renderMenu}
                </section>
            </div>
        </div>
    );
}

export default connect(({applicationData}) => ({
    user: applicationData.user,
    paired: applicationData.firebaseData && applicationData.firebaseData.location,
    firebaseSignedIn: applicationData.firebaseSignedIn,
}), {logoutRequest, receiveModeRequest, pictureModeRequest})(withWidth()(HeaderNavNavigationItems));
