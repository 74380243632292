import {Types} from "../actions/applicationData";
import ToastService from "../../services/ToastService";

export const AUTH_STATE_LOADING = 'loading';
export const AUTH_STATE_LOGGED_IN = 'logged_in';
export const AUTH_STATE_LOGGED_OUT = 'logged_out';

const INITIAL_STATE = {
    auth: {
        state: AUTH_STATE_LOGGED_OUT,
    },
    firebaseSignedIn: null,
    firebaseMeta: null,
    firebaseData: null,
    receiveMode: false,
    pictureMode: false,
    signature: null,
    user: null,
    signatureSuccessful: null,
    pictureModeData: {
        order: null,
        pictures: {},
    },
};

export default function applicationData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.PICTURE_TOGGLE_SELECTION: {
            const key = action.payload.key;
            const value = action.payload.value;

            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    pictures: {
                        ...state.pictureModeData.pictures,
                        [key]: {
                            ...state.pictureModeData.pictures[key],
                            selected: value || !state.pictureModeData.pictures[key].selected,
                        }
                    },
                }
            };
        }
        case Types.PICTURE_SAVE: {
            if (Object.keys(state.pictureModeData.pictures).length >= 10) {
                ToastService.warning('Maximum number of pictures in memory (10), please remove some pictures before taking more pictures.');
                return {...state};
            }

            const key = Math.random().toString(36).substr(2, 5);
            let pictures = {...state.pictureModeData.pictures};
            pictures[key] = {
                key: key,
                data: action.payload.data,
                selected: true,
            };
            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    pictures: pictures,
                }
            }
        }
        case Types.PICTURE_REMOVE: {
            let pictures = state.pictureModeData.pictures;
            if (!action.payload.key) {
                pictures = INITIAL_STATE.pictureModeData.pictures;
            } else {
                delete pictures[action.payload.key];
            }
            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    pictures: {...pictures},
                }
            }
        }
        case Types.ORDER_UPLOAD_SUCCESS: {
            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    order: INITIAL_STATE.pictureModeData.order, // clear order after upload
                    pictures: INITIAL_STATE.pictureModeData.pictures, // clear pictures after upload
                }
            }
        }
        case Types.ORDER_CLEAR: {
            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    order: INITIAL_STATE.pictureModeData.order,
                    pictures: INITIAL_STATE.pictureModeData.pictures,
                }
            }
        }
        case Types.ORDER_SUCCESS: {
            return {
                ...state,
                pictureModeData: {
                    ...state.pictureModeData,
                    order: action.payload.data,
                    pictures: INITIAL_STATE.pictureModeData.pictures,
                }
            }
        }
        case Types.SIGNATURE_SUCCESSFUL: {
            return {
                ...state,
                signatureSuccessful: action.payload.data,
            }
        }
        case Types.SET_SIGNATURE: {
            return {
                ...state,
                signature: action.payload.data,
            }
        }
        case Types.LOGIN_SUCCESS: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    state: AUTH_STATE_LOGGED_IN,
                }
            }
        }
        case Types.SET_FIREBASE_SIGNED_IN: {
            return {
                ...state,
                firebaseSignedIn: action.payload.signedIn,
            }
        }
        case Types.SET_FIREBASE_DATA: {
            return {
                ...state,
                firebaseData: action.payload.data,
            }
        }
        case Types.SET_FIREBASE_META: {
            return {
                ...state,
                firebaseMeta: action.payload.data,
            }
        }
        case Types.ME_SUCCESS: {
            return {
                ...state,
                user: action.payload.data,
            }
        }
        case Types.LOGOUT_SUCCESS: {
            return {
                ...state,
                user: INITIAL_STATE.user,
                auth: {
                    ...state.auth,
                    state: AUTH_STATE_LOGGED_OUT,
                }
            }
        }
        case Types.RECEIVE_MODE_SUCCESS: {
            return {
                ...state,
                receiveMode: true,
                pictureMode: false,
            }
        }
        case Types.EXIT_RECEIVE_MODE_SUCCESS: {
            return {
                ...state,
                receiveMode: false,
            }
        }
        case Types.PICTURE_MODE_SUCCESS: {
            return {
                ...state,
                pictureMode: true,
                receiveMode: false,
            }
        }
        case Types.EXIT_PICTURE_MODE_SUCCESS: {
            return {
                ...state,
                pictureMode: false,
            }
        }
        default:
            return state;
    }
}
