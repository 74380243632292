import React from 'react';
import Main from "../main/Main";
import {BrowserRouter as Router} from "react-router-dom";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {lightTheme, darkTheme} from '../theme/theme';
import CssBaseline from "@material-ui/core/CssBaseline";
import {Provider} from "react-redux";
import {store, sagaMiddleware} from "../services/StoreService";
import rootSaga from "../redux/sagas";
import * as ThemeService from "../services/ThemeService";
import {createTheme} from "@material-ui/core";
import PWAUpdater from "../services/PWAUpdater";
import FirebaseHandler from "../services/FirebaseHandler";

sagaMiddleware.run(rootSaga);

function Boot() {
    const storedLightMode = ThemeService.getLightMode();
    const theme = storedLightMode === 'light' ? createTheme(lightTheme) : createTheme(darkTheme);

    return (
        <Provider store={store}>
            <PWAUpdater/>
            <FirebaseHandler/>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Main/>
                </MuiThemeProvider>
            </Router>
        </Provider>
    );
}

export default Boot;
