import {getEnvironmentVariables} from "./environment";
import * as config from "./config";

const envApi = getEnvironmentVariables().api;

const api_common = {
    baseURL: envApi.baseURL,
    clientId: config.api.clientId
};

export const api = {
    ...api_common,
};