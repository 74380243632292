const DEVELOPMENT = 'development';
const TEST = 'test';
const PRODUCTION = 'production';

export const getEnvironmentVariables = () => {

    const url = window.location.host.split(':')[0]; // remove port
    const parts = url.split('.');
    const tenantParts = parts[0].split('-');
    tenantParts.pop();
    parts[0] = tenantParts.join('-');
    const apiUrl = parts.join('.');

    // default values
    let values = {
        domain: parts[0],
    };

    switch (process.env.NODE_ENV) {
        case TEST:
        case DEVELOPMENT:
            values = {
                ...values,
                api: {
                    // get subdomain
                    baseURL: `https://${apiUrl}/api/v3`,
                },
                auth: {
                    // get subdomain
                    baseURL: `https://${apiUrl}/en/oauth/authorize`,
                    redirectURL: `https://${window.location.host}/login`,
                },
            }
            break;
        case PRODUCTION:
            values = {
                ...values,
                api: {
                    // get subdomain
                    baseURL: `https://${apiUrl}/api/v3`,
                },
                auth: {
                    // get subdomain
                    baseURL: `https://${apiUrl}/en/oauth/authorize`,
                    redirectURL: `https://${window.location.host}/login`,
                },
            }
            break;
        default:
            break;
    }

    return values;
}