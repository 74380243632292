import "./Pin.css";
import {styles} from "../theme/theme";
import {Modal} from "@material-ui/core";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function FixablyModal(props) {
    const {
        fullScreen,
        children,
        open,
        onClose,
        title,
        titleIcon,
    } = props;

    const theme = useTheme();
    const bgColor = theme.palette.type === 'light' ? styles.colors.fixablyBgLight : styles.colors.fixablyBgDark;

    const styleDialog = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 12,
        padding: 12,
    }

    const styleFullScreen = {
        width: '100%',
        height: '100%',
    }

    const layoutStyle = fullScreen ? styleFullScreen : styleDialog;

    const closeButtonClicked = () => {
        onClose();
    };

    return <Modal
        open={open}
        onClose={onClose}>
        <div style={{
            ...layoutStyle,
            paddingTop: 12,
            backgroundColor: bgColor,
            border: 'solid 2px ' + styles.colors.fixablyPrimary,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                {
                    fullScreen &&
                    <div style={{width: 100, display: 'flex', justifyContent: 'left', marginLeft: 12}}></div>
                }
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {titleIcon}{titleIcon && <section style={{marginRight: 4}}/>}
                    {title && <h2 style={{margin: 0}}>{title}</h2>}
                </div>
                {
                    fullScreen &&
                    <div style={{width: 100, display: 'flex', justifyContent: 'end', marginRight: 12}}>
                        <IconButton onClick={() => closeButtonClicked()}><CloseIcon/></IconButton>
                    </div>
                }
            </div>
            {children}
        </div>
    </Modal>;
}