import axiosInstance from "../api";

const TOKEN = 'token';
const REFRESH_TOKEN = 'refresh_token';

export const saveToken = (token) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem(TOKEN, token);
}

export const loadToken = () => {
    const token = localStorage.getItem(TOKEN);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return token;
}

export const clearToken = () => {
    delete axiosInstance.defaults.headers.common["Authorization"];
    localStorage.removeItem(TOKEN);
}

export const saveRefreshToken = (token) => {
    localStorage.setItem(REFRESH_TOKEN, token);
}

export const loadRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
}

export const clearRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
}