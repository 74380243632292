import * as Colors from '@material-ui/core/colors';
import {createTheme} from "@material-ui/core";

const colors = {
    fixablyBlack: '#1d1e2d',
    fixablyPrimary: '#007cba',
    fixablySecondary: '#ff7c34',
    fixablyWhite: '#ffffff',
    fixablyBgLight: '#ffffff',
    fixablyBgDark: '#303030',
};

export const styles = {
    colors: colors,
}

export const lightTheme = {
    palette: {
        // type: 'light',
        primary: {
          main: colors.fixablyPrimary,
        },
        secondary: {
            main: colors.fixablySecondary,
        },
        error: Colors.deepOrange,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    }
};

export const darkTheme = {
    palette: {
        type: 'dark',
        primary: {
            main: colors.fixablyPrimary,
        },
        secondary: {
            main: colors.fixablySecondary,
        },
        error: Colors.deepOrange,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    }
};

export default createTheme(lightTheme);
