import React from "react";
import {Button, makeStyles, Paper, StepButton, Stepper} from "@material-ui/core";
import {connect} from "react-redux";
import UnlockIcon from "@material-ui/icons/LockOpen";
import {exitPictureModeRequest, orderClear, orderRequest} from "../../redux/actions/applicationData";
import Step from '@material-ui/core/Step';
import Camera from "../../components/Camera";
import SelectImages from "../../components/SelectImages";
import FixablyModal from "../../components/FixablyModal";
import ToastService from "../../services/ToastService";
import QrReader from 'react-qr-reader';
import QrIcon from "@material-ui/icons/CropFree";
import IconButton from "@material-ui/core/IconButton";
import ImageUpload from "../../components/ImageUpload";
import Numpad from "../../components/Numpad";
import {isNumeric} from "../../utils/stringUtils";

function PictureMode(props) {
    const {
        order,
        exitPictureModeRequest,
        orderClear,
        orderRequest,
        pictures,
    } = props;

    const handleExitPictureMode = () => {
        exitPictureModeRequest();
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }));

    // START OF STEPPER
    function getSteps() {
        return ['Take pictures', 'Upload pictures', 'Select pictures and upload (' + Object.keys(pictures).length + ')' ];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Camera/>;
            case 1:
                return <ImageUpload/>;
            case 2:
                return <SelectImages/>;
            default:
                return 'Unknown step';
        }
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [orderModalOpen, setOrderModalOpen] = React.useState(false);
    const [qrModalOpen, setQrModalOpen] = React.useState(false);

    const steps = getSteps();

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    if (!order && activeStep !== 0) {
        setActiveStep(0);
    }

    const renderStepper = () => {
        return (
            <div className={classes.root} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Paper>
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
                <Paper style={{padding: 12, marginTop: 12, flex: 1}}>
                    <div style={{height: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <section style={{flex: 1}}>{getStepContent(activeStep)}</section>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }

    const onSetOrderNumber = (data) => {
        if (isOrderNumber(data)) {
            setOrderModalOpen(false);
            orderRequest({order: data});
            return;
        }
        ToastService.warning('Invalid order number');
    }

    const handleQrScanError = () => {
        setQrModalOpen(false);
        ToastService.error('Something went wrong reading the QR-code');
    }

    const handleQrScan = (data) => {
        if (isOrderNumber(data)) {
            orderRequest({order: data});
            setQrModalOpen(false);
            ToastService.success('Order ' + data + ' scanned');
        }
    }

    const isOrderNumber = (id) => {
      return isNumeric(id);
    };

    const renderHeader = () => {
        return (
            <Paper style={{
                width: '100%',
                padding: 12,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{flex: 1}}>
                    {renderOrderDetails()}
                </div>
                <div>
                    {renderHeaderControls()}
                </div>
                <FixablyModal
                    titleIcon={null}
                    title={'Scan QR-code'}
                    open={qrModalOpen}
                    onClose={() => setQrModalOpen(false)}>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 300,
                        marginTop: 24,
                        marginBottom: 24
                    }}>
                        <QrReader
                            delay={250}
                            onError={handleQrScanError}
                            onScan={handleQrScan}
                            style={{ width: '100%' }}
                        />
                    </div>
                </FixablyModal>
                <Numpad
                    label={'Id'}
                    title={'Enter order number'}
                    open={orderModalOpen}
                    onClose={() => setOrderModalOpen(false)}
                    onDone={(data) => onSetOrderNumber(data)}
                />
            </Paper>
        )
    }

    const setOrderClicked = () => {
        setOrderModalOpen(true);
    }

    const renderHeaderControls = () => {
        if (order) {
            return (
                <Button variant="contained" color="primary" onClick={() => {
                    orderClear()
                }}>
                    Clear order
                </Button>
            );
        }

        return (
            <section>
                <IconButton onClick={() => setQrModalOpen(true)} style={{marginRight: 12}} label={'Scan'} color="primary" aria-label="Scan QR-code">
                    <QrIcon/>
                </IconButton>
                <Button disabled={orderModalOpen} variant="contained" color="primary" onClick={() => setOrderClicked()}>
                    Set order
                </Button>
            </section>
        );
    }

    const renderOrderDetails = () => {
        if (order) {
            return (
                <section style={{fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <section style={{marginRight: 24, flex: 1}}>
                        <div><strong>Order number:</strong> {order.id}</div>
                        {order.endUser && <div><strong>End user:</strong> {order.endUser}</div>}
                        {order.customer && <div><strong>Customer:</strong> {order.customer}</div>}
                    </section>
                    {/*<Alert severity={'error'} style={{flex: 1, marginRight: 12, fontSize: 12}}>*/}
                    {/*    <AlertTitle style={{fontSize: 14}}>Issue</AlertTitle>*/}
                    {/*    {order.issue}*/}
                    {/*</Alert>*/}
                </section>);
        }

        return (<section>
            <div>No order selected</div>
        </section>);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%'}}>
            {renderHeader()}
            <div style={{flex: 1, width: '100%'}}>
                <div style={{
                    padding: 12,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                }}>
                    <div style={{flex: 1}}>
                        {renderStepper()}
                    </div>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <Button
                    style={{marginLeft: 12, marginBottom: 12}}
                    variant='outlined'
                    color='default'
                    onClick={() => handleExitPictureMode()}
                    endIcon={<UnlockIcon/>}>
                    Exit picture mode
                </Button>
            </div>
        </div>
    );
}

export default connect(({applicationData}) => ({
    order: applicationData.pictureModeData.order,
    pictures: applicationData.pictureModeData.pictures,
}), {exitPictureModeRequest, orderClear, orderRequest})(PictureMode);
