import "./Pin.css";
import React, {createRef, useEffect, useState} from "react";
import SignaturePad from "react-signature-pad-wrapper";
import {styles} from "../theme/theme";
import {Button} from "@material-ui/core";
import SignIcon from "@material-ui/icons/BorderColor";
import UndoIcon from "@material-ui/icons/RotateLeft";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Signature(props) {
    const {
        disclaimer,
        onSign,
    } = props;

    function setupCanvas(canvas) {
        // Get the device pixel ratio, falling back to 1.
        let dpr = window.devicePixelRatio || 1;
        // Get the size of the canvas in CSS pixels.
        let rect = canvas.getBoundingClientRect();
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr * 0.8;
        let ctx = canvas.getContext('2d');
        // Scale all drawing operations by the dpr
        ctx.scale(dpr, dpr);
        return ctx;
    }

    useEffect(() => {
        setupCanvas(document.querySelector('.fxb-sign-canvas canvas'));
    }, []);

    const signaturePad = createRef();

    const onClearPressed = () => {
        signaturePad.current.clear();
    }

    const [signing, setSigning] = useState(false);

    function endSigning() {
        setSigning(false);
    }

    return (
        <section style={{width: '80vw'}}>
            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{border: 'dashed 2px ' + styles.colors.fixablyBlack, width: '100%'}}
                     className="fxb-sign-canvas">
                    <SignaturePad ref={signaturePad} redrawOnResize={true}
                                  options={{minWidth: 2, maxWidth: 4, penColor: styles.colors.fixablyPrimary}}/>
                </div>
            </div>
            {disclaimer && <div style={{marginTop: 6}}>{disclaimer}</div>}
            <div style={{marginTop: 6}}>
                {signing
                    ? <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 36}}>
                        <CircularProgress size={36} color='secondary'/>
                    </div>
                    : <section>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                                const isEmpty = signaturePad.current.isEmpty();
                                if (!isEmpty) {
                                    setSigning(true);
                                    onSign({signaturePad: signaturePad.current, doAfter: endSigning})
                                }
                            }}
                            endIcon={<SignIcon/>}>
                            Sign
                        </Button>
                        <Button
                            style={{marginLeft: 12}}
                            variant='text'
                            color='default'
                            onClick={() => {
                                onClearPressed()
                            }}
                            endIcon={<UndoIcon/>}>
                            Clear
                        </Button>
                    </section>
                }
            </div>
        </section>
    );
}