import {Button, TextField} from "@material-ui/core";
import {NumericKeyboard} from "react-numeric-keyboard";
import FixablyModal from "./FixablyModal";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import {useEventListener} from "../hooks/useEventListener";
import {backspace, isNumeric} from "../utils/stringUtils";

export default function Numpad(props) {
    const {
        titleIcon,
        title,
        open,
        onClose,
        onDone,
        label,
        type,
    } = props;

    const [numpadValue, setNumpadValue] = React.useState('');

    const numpadDone = (
        <Button disabled={!open} onClick={() => handleNumpadValue('Enter')} label={'Done'} color={'primary'} aria-label="Done"
                style={{width: '100%', height: '100%'}}>
            <CheckIcon/>
        </Button>
    );

    const handleNumpadValue = (key) => {
        if (!open) {
            return;
        }
        if (key === 'Backspace') {
            setNumpadValue(backspace(numpadValue));
            return;
        }
        if (key === 'Enter') {
            onDone(numpadValue)
            return;
        }
        if (isNumeric(key)) {
            setNumpadValue(numpadValue + key);
        }
    }

    const keyDownHandler = (event) => {
      handleNumpadValue(event.key);
    };

    useEventListener('keydown', keyDownHandler);

    return <FixablyModal
        fullScreen
        titleIcon={titleIcon}
        title={title}
        open={open}
        onClose={() => onClose()}
    >
        <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 300,
            marginTop: 24,
            marginBottom: 24,
        }}>
            <TextField value={numpadValue} id="order-number" label={label} variant="filled" style={{width: '100%'}}
                       type={type || 'number'} inputProps={{inputMode: 'numeric'}} disabled/>
        </div>
        <NumericKeyboard containerClassName={'numpad-container'}
                         mode="spaced"
                         leftIcon={numpadDone}
                         style={{position: 'relative'}}
                         isOpen={open}
                         fullWidth
                         onChange={(e) => handleNumpadValue(e.name)}
        />
    </FixablyModal>;
}