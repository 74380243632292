export const setLightMode = (lightMode) => {
    localStorage.setItem('lightMode', lightMode);
}

export const getLightMode = () => {
    if (localStorage.hasOwnProperty('lightMode')) {
        return localStorage.getItem('lightMode');
    }

    return 'light';
}