import React from 'react';
import "./HeaderNav.css";
import HeaderNavNavigationItems from "./HeaderNavNavigationItems";
import Hidden from "@material-ui/core/Hidden";
import {makeStyles, withTheme} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import MobileLogoLight from "../../assets/fixably-logo.svg";
import MobileLogoDark from "../../assets/fixably-logo-darkmode.svg";
import SideDrawerToggleButton from "../SideDrawer/SideDrawerToggleButton";
import Configuration from "../../configuration";
import { useTheme } from '@material-ui/core/styles';

function HeaderNav() {
  const theme = useTheme();

  const mobileLogo = theme.palette.type === 'light' ? MobileLogoLight : MobileLogoDark;

  const useStyles = makeStyles({
    appBar: {
      top: 0,
      bottom: 'auto',
    },
    root: {
      flexGrow: 1,
    },
    logoMobile: {
      backgroundImage: `url(${mobileLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      marginLeft: theme.spacing(2),
      height: 40,
      width: 150,
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar style={{height: 64}}>
          {Configuration.client.sideDrawerEnabled && <SideDrawerToggleButton/>}
          <div style={{flex: '1'}}>
            <Hidden smDown>
                <Box className={classes.logoMobile}/>
            </Hidden>
          </div>
          <HeaderNavNavigationItems/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withTheme(HeaderNav);
