import React, {useState} from 'react';
import {CircularProgress, makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import MobileLogoLight from '../../assets/fixably-logo.svg';
import MobileLogoDark from '../../assets/fixably-logo-darkmode.svg';
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import {styles} from "../../theme/theme";
import {connect} from "react-redux";
import {loginRequest} from "../../redux/actions/applicationData";
import {useTheme} from "@material-ui/core/styles";

import Configuration from "../../configuration";

import {getEnvironmentVariables} from "../../configuration/environment";
import {CURRENT_VERSION} from "../../services/PWAUpdater";

const useStyles = makeStyles(theme => ({
    highlightButton: {
        background:
            theme.palette.type === 'light'
                ? `linear-gradient(90deg, ${styles.colors.fixablyPrimary} 0%, ${styles.colors.fixablyPrimary} 25%, ${styles.colors.fixablyWhite} 50%, ${styles.colors.fixablyPrimary} 75%, ${styles.colors.fixablyPrimary} 100%)`
                : `linear-gradient(90deg, ${styles.colors.fixablyPrimary} 0%, ${styles.colors.fixablyPrimary} 25%, ${styles.colors.fixablyWhite} 50%, ${styles.colors.fixablyPrimary} 75%, ${styles.colors.fixablyPrimary} 100%)`,
        backgroundSize: '200%',
        backgroundPosition: '0% 0%',
        transition: 'all 250ms ease',
        border: 0,
        borderRadius: 24,
        color: 'white',
        height: 48,
        width: '100%',
        '&:hover': {
            backgroundPosition: '100% 0%',
            transition: 'all 250ms ease',
        },
    },
    logoMobile: {
        backgroundImage: `url(${MobileLogoLight})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        margin: theme.spacing(1),
        marginBottom: theme.spacing(5),
        height: 60,
        width: 200,
    },
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(6),
    },
    textField: {
        width: '100%',
    },
    cardHeader: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
        color: theme.palette.type === 'light' ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.getContrastText(theme.palette.primary.dark),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

let loginTimeoutHandle = null;

function Login(props) {

    const {
        loginRequest,
    } = props;

    const authURL = getEnvironmentVariables().auth.baseURL;
    const redirectURL = getEnvironmentVariables().auth.redirectURL;
    const scopes = [
        'user:view',
        'signature:view',
        'signature:sign',
        'signature_terminal:view',
        'signature_terminal:register',
    ];
    const queryParams = [
        'response_type=code',
        `client_id=${Configuration.api.clientId}`,
        `redirect_uri=${redirectURL}`,
        `scope=${scopes.join(' ')}`
    ];
    const loginUrl = `${authURL}?${queryParams.join('&')}`;

    const theme = useTheme();
    const mobileLogo = theme.palette.type === 'light' ? MobileLogoLight : MobileLogoDark;

    const classes = useStyles();

    const [loggingIn, setLoggingIn] = useState(false);

    function endLoggingIn() {
        setLoggingIn(false);
    }

    if (loginTimeoutHandle === null) {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === 'code') {
                const code = decodeURIComponent(pair[1]);
                loginTimeoutHandle = setTimeout(() => {
                    setLoggingIn(true);
                    loginRequest({code: code, doAfter: () => endLoggingIn()});
                }, 0);
            }
        }
    }

    return (
        <Box style={ {height: '100vh', width: '100%', display: 'flex', flexDirection: 'column',} }>
            <Box className={classes.container}>
                <Paper className={classes.paper} elevation={4}>
                    <Box className={classes.logoMobile} style={{backgroundImage: `url(${mobileLogo})`}}/>
                    {loggingIn ?
                        <CircularProgress color='primary'/> :
                        <Button
                            href={loginUrl}
                            className={clsx(classes.highlightButton)}>Login</Button>}
                </Paper>
            </Box>
            <Box style={{width: '100%', textAlign: 'right', paddingRight: 6 }}><Typography color={'textSecondary'} variant={'overline'}>v. {CURRENT_VERSION}</Typography></Box>
        </Box>
    );
}

export default connect(null, {
    loginRequest,
})(Login);
