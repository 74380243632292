import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";

import './SideDrawerToggleButton.css';
import {navigationDrawerToggle} from "../../redux/actions/UI";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0),
  },
}));

function SideDrawerToggleButton(props) {
  const classes = useStyles();

  const {
    navigationDrawerToggle,
  } = props;

  const toggleDrawer = () => {
    navigationDrawerToggle();
  };

  return (
    <IconButton className={classes.button} aria-label="Menu" onClick={() => toggleDrawer()}>
      <MenuIcon size={24}/>
    </IconButton>
  );
}

export default connect(null, {
  navigationDrawerToggle
})(SideDrawerToggleButton);

