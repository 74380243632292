import {routes} from "./routes";
import {api} from "./api";
import {firebase} from "./firebase";
import {client} from "./client";

const Configuration = {
    api,
    routes,
    firebase,
    client,
};

export default Configuration;
