import React from "react";
import NotificationSystem from 'react-notification-system';
import {styles} from "../theme/theme";
import './ToastService.css';

const notificationSystem = React.createRef();

const defaultOpts = {};

const style = {
    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            // fontFamily: styles.typography.fontFamily,
        },
        success: { // Applied only to the success notification item
            color: styles.colors.fixablyPrimary
        },
        warning: {
            color: styles.colors.fixablySecondary
        },
        error: {
            color: styles.colors.fixablySecondary
        },
        info: {
            color: styles.colors.fixablyPrimary
        },
    }
};

function ToastContainer() {
    return (
        <NotificationSystem ref={notificationSystem} style={style}/>
    );
}

function error(message, title = 'Error', extra = {}) {
    addNotification({
        ...defaultOpts,
        message,
        title,
        level: 'error',
        ...extra
    });
}

function info(message, title = '', extra = {}) {
    addNotification({
        ...defaultOpts,
        message,
        title,
        level: 'info',
        ...extra
    });
}

function success(message, title = '', extra = {}) {
    addNotification({
        ...defaultOpts,
        message,
        title,
        level: 'success',
        ...extra
    });
}

function warning(message, title = 'Warning', extra = {}) {
    addNotification({
        ...defaultOpts,
        message,
        title,
        level: 'warning',
        ...extra
    });
}

function addNotification(opts) {
    const notification = notificationSystem.current;
    notification.addNotification(opts);
}

const ToastService = {
    ToastContainer,
    error,
    info,
    success,
    warning,
}

export default ToastService;
