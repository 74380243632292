import {all, call, delay, fork, put, select} from 'redux-saga/effects';
import * as actions from '../actions/applicationData';
import * as api from '../../api/applicationData';
import axiosInstance from '../../api/index';
import {takeLatest} from "@redux-saga/core/effects";
import ToastService from "../../services/ToastService";
import {HistoryWrapper} from "../../services/HistoryWrapper";
import {
    clearRefreshToken,
    clearToken,
    loadRefreshToken,
    loadToken,
    saveRefreshToken,
    saveToken
} from "../../services/TokenService";
import {
    clearDeviceIdentifier, clearLocation,
    loadDeviceIdentifier,
    loadLocation,
    saveDeviceIdentifier,
    saveLocation
} from "../../services/FirebaseService";
import Configuration from "../../configuration";

function* tokenLogin(action) {
    const {
        doAfter,
    } = action.payload;

    const token = loadToken();

    if (!token) {
        doAfter();
        return;
    }
    saveToken(token);

    try {
        const meResponse = yield call(api.me);
        yield put(actions.meSuccess(meResponse.data));
        yield put(actions.loginSuccess());
        HistoryWrapper.history.push('/dashboard');

        yield call(setFirebaseMeta);
    } catch (e) {
        clearToken();
    }
    doAfter();
}

export async function refreshAccessToken() {
    // also removes from headers
    clearToken();
    const postData = {
        "grant_type": "refresh_token",
        "client_id": Configuration.api.clientId,
        "refresh_token": loadRefreshToken(),
    }

    try {
        const response = await axiosInstance.post('/auth/token', postData);
        const {data} = response;
        saveToken(data.accessToken);
        if (data.refreshToken) {
            saveRefreshToken(data.refreshToken);
        }
    } catch (e) {
        clearRefreshToken();
        clearToken();
    }
}

function* login(action) {
    const {
        code,
        doAfter,
    } = action.payload;
    try {
        const response = yield call(api.tokenRequest, {code});
        const {data} = response;

        if (data.accessToken) {
            saveToken(data.accessToken);
            saveRefreshToken(data.refreshToken);
            const meResponse = yield call(api.me);
            yield put(actions.meSuccess(meResponse.data));
            yield put(actions.loginSuccess());
            // ToastService.success('Successfully logged in.');
            HistoryWrapper.history.push('/dashboard');

            yield call(setFirebaseMeta);
        } else {
            clearToken();
            clearRefreshToken();
        }
    } catch (e) {
        doAfter();
        clearToken();
        clearRefreshToken();
    }
}

function* setFirebaseMeta() {
    const firebaseLocation = loadLocation();
    const firebaseDeviceIdentifier = loadDeviceIdentifier();

    if (firebaseLocation && firebaseDeviceIdentifier) {
        yield put(actions.setFirebaseMeta({
            location: firebaseLocation,
            identifier: firebaseDeviceIdentifier,
        }));
    } else {
        const registerResponse = yield call(api.registerTerminal);
        saveDeviceIdentifier(registerResponse.data.identifier);
        saveLocation(registerResponse.data.location);
        yield put(actions.setFirebaseMeta(registerResponse.data));
    }

    yield call(getTerminal);
}

function* orderUpload(action) {
    const {
        id,
        doAfter,
    } = action.payload;

    const state = yield select();
    const pictures = state.applicationData.pictureModeData.pictures;

    let pictureKeys = [];
    Object.keys(pictures).forEach((key) => {
       if (pictures[key].selected) pictureKeys.push(key);
    })

    try {
        yield all(pictureKeys.map(key => call(orderUploadSingle, {id, fileData: pictures[key].data})));
        ToastService.success('Upload successful')
    } catch (e) {
        ToastService.error('Upload failed');
        // This disables the loading indicator. We only disable it in the catch since the
        // view is destroyed if this is not caught
        doAfter();
    }
}

function* orderUploadSingle({id, fileData}) {
    try {
        yield call(api.orderUpload, {id, fileData});
        yield put(actions.orderUploadSuccess({id}));
    } catch (e) {
        ToastService.error('Upload failed');
    }
}

function* getOrder(action) {
    const {
        order // id
    } = action.payload;

    try {
        const orderResponse = yield call(api.getOrder, {id: order});

        // data: {
        //     id: id,
        //     contactName: 'Joakim Sandqvist',
        //     customerName: 'J.S.A. Sandqvist Oy',
        //     issue: 'Broken screen',
        // }

        let issue = null;
        // find the most recent issue
        if (orderResponse.data.notes && orderResponse.data.notes.items) {
            orderResponse.data.notes.items.forEach((note) => {
               if (note.type === "ISSUE") {
                   if (!issue || note.id > issue.id) {
                       issue = note;
                   }
               }
            });
        }

        const endUser = orderResponse.data.contact ? orderResponse.data.contact.fullName : null;

        let customer = null;
        if (orderResponse.data.customer) {
            customer = orderResponse.data.customer.company ?
                orderResponse.data.customer.company :
                orderResponse.data.customer.firstName + ' ' + orderResponse.data.customer.lastName;
        }

        const data = {
            id: orderResponse.data.id,
            endUser: endUser,
            customer: customer,
            issue: issue ? issue.text : null,
        }

        yield call(setOrder, data);
    } catch (e) {
        ToastService.error('Could not fetch the order. Order might not exist.');
    }
}

function* setOrder(order) {
    yield put(actions.orderSuccess(order));
}

function* clearOrder() {
    yield call(actions.orderClear);
}

function* getSignature(action) {
    const {
        identifier
    } = action.payload;

    try {
        const signatureResponse = yield call(api.getSignature, {identifier});
        yield put(actions.setSignature(signatureResponse.data));
    } catch (e) {
        yield call(clearSignature);
    }
}

function* sign(action) {
    const {
        identifier,
        fileData,
        doAfter,
    } = action.payload;

    try {
        yield call(api.sign, {identifier, fileData})
        ToastService.success('The document(s) have been signed successfully!');
        yield put(actions.setSignatureSuccessful(true));
        yield delay(10000);
        yield put(actions.setSignatureSuccessful(null));
    } catch (e) {
        yield call(clearSignature);
        ToastService.warning('Something went wrong. Please contact the staff.');
        doAfter();
    }
}

function* clearSignature() {
    yield put(actions.setSignature(null));
}

function* getTerminal() {
    const deviceIdentifier = loadDeviceIdentifier();
    try {
        let terminalResponse = yield call(api.getTerminal, {identifier: deviceIdentifier});

        const firebaseData = {
            ...terminalResponse.data,
            firebase: {
                ...Configuration.firebase,
                apiKey: terminalResponse.data.firebaseApiKey,
            },
        }

        if (firebaseData?.firebaseApiKey) {
            delete(firebaseData.firebaseApiKey);
        }

        yield put(actions.setFirebaseData(firebaseData));
    } catch (e) {
        ToastService.warning('Error connecting to Fixably.');
    }
}

function* setFirebaseSignedIn(action) {
    yield put(actions.setFirebaseSignedInSuccess(action.payload.signedIn));
}

function* clearFirebase() {
    clearDeviceIdentifier();
    clearLocation();
    yield put(actions.setFirebaseData(null));
    yield put(actions.setFirebaseMeta(null));
    ToastService.warning('The device identifier no longer exists. Device is registered again on next login. Logging out.');
    yield call(logout);
}

function* logout() {
    clearToken();
    yield put(actions.logoutSuccess());
}

function* receiveMode() {
    yield put(actions.receiveModeSuccess());
}

function* exitReceiveMode(action) {
    const {
        pin,
        force
    } = action.payload;

    const state = yield select();
    if (force) {
        yield put(actions.exitReceiveModeSuccess());
    } else if (pin === state.applicationData.firebaseData.unlockCode) {
        yield put(actions.exitReceiveModeSuccess());
    } else {
        ToastService.warning('Incorrect PIN', '');
    }
}

// function* pictureSave(action) {
//     const {
//         data
//     } = action;
//     yield put(actions.pictureSave({data}));
// }
//
// function* pictureRemove(action) {
//     const {
//         key,
//     } = action;
//     yield put(actions.pictureRemove({key}));
// }
//
// function* pictureToggleSelection({key}) {
//     yield put(actions.pictureToggleSelection({key}));
// }

function* pictureMode() {
    yield put(actions.pictureModeSuccess());
}

function* exitPictureMode() {
    yield put(actions.exitPictureModeSuccess());
}

// START OF WATCHERS
// function* watchPictureToggleSelection() {
//     yield takeLatest(actions.Types.PICTURE_TOGGLE_SELECTION, pictureToggleSelection);
// }
//
// function* watchPictureSave() {
//     yield takeLatest(actions.Types.PICTURE_SAVE, pictureSave);
// }
//
// function* watchPictureRemove() {
//     yield takeLatest(actions.Types.PICTURE_REMOVE, pictureRemove);
// }

function* watchSetFirebaseSignedIn() {
    yield takeLatest(actions.Types.SET_FIREBASE_SIGNED_IN, setFirebaseSignedIn);
}

function* watchOrderUploadRequest() {
    yield takeLatest(actions.Types.ORDER_UPLOAD_REQUEST, orderUpload);
}

function* watchOrderRequest() {
    yield takeLatest(actions.Types.ORDER_REQUEST, getOrder);
}

function* watchOrderClear() {
    yield takeLatest(actions.Types.ORDER_CLEAR, clearOrder);
}

function* watchSignRequest() {
    yield takeLatest(actions.Types.SIGN_REQUEST, sign);
}

function* watchClearSignature() {
    yield takeLatest(actions.Types.CLEAR_SIGNATURE, clearSignature);
}

function* watchSignatureRequest() {
    yield takeLatest(actions.Types.SIGNATURE_REQUEST, getSignature);
}

function* watchTerminalRequest() {
    yield takeLatest(actions.Types.TERMINAL_REQUEST, getTerminal);
}

function* watchTokenLogin() {
    yield takeLatest(actions.Types.TOKEN_LOGIN_REQUEST, tokenLogin);
}

function* watchLogin() {
    yield takeLatest(actions.Types.LOGIN_REQUEST, login);
}

function* watchLogout() {
    yield takeLatest(actions.Types.LOGOUT_REQUEST, logout);
}

function* watchReceiveMode() {
    yield takeLatest(actions.Types.RECEIVE_MODE_REQUEST, receiveMode);
}

function* watchExitReceiveMode() {
    yield takeLatest(actions.Types.EXIT_RECEIVE_MODE_REQUEST, exitReceiveMode);
}

function* watchPictureMode() {
    yield takeLatest(actions.Types.PICTURE_MODE_REQUEST, pictureMode);
}

function* watchExitPictureMode() {
    yield takeLatest(actions.Types.EXIT_PICTURE_MODE_REQUEST, exitPictureMode);
}

// END OF WATCHERS

const applicationDataSagas = [
    // fork(watchPictureToggleSelection),
    // fork(watchPictureRemove),
    // fork(watchPictureSave),
    fork(watchSetFirebaseSignedIn),
    fork(watchOrderUploadRequest),
    fork(watchOrderRequest),
    fork(watchOrderClear),
    fork(watchSignRequest),
    fork(watchClearSignature),
    fork(watchSignatureRequest),
    fork(watchTerminalRequest),
    fork(watchTokenLogin),
    fork(watchLogin),
    fork(watchLogout),
    fork(watchReceiveMode),
    fork(watchExitReceiveMode),
    fork(watchPictureMode),
    fork(watchExitPictureMode),
];

export default applicationDataSagas;