export const Types = {
    TOKEN_LOGIN_REQUEST: 'application_data/token_login_request',
    LOGIN_REQUEST: 'application_data/login_request',
    LOGIN_SUCCESS: 'application_data/login_success',
    LOGOUT_REQUEST: 'application_data/logout_request',
    LOGOUT_SUCCESS: 'application_data/logout_success',
    ME_REQUEST: 'application_data/me_request',
    ME_SUCCESS: 'application_data/me_success',
    RECEIVE_MODE_REQUEST: 'application_data/receive_mode_request',
    RECEIVE_MODE_SUCCESS: 'application_data/receive_mode_success',
    EXIT_RECEIVE_MODE_REQUEST: 'application_data/exit_receive_mode_request',
    EXIT_RECEIVE_MODE_SUCCESS: 'application_data/exit_receive_mode_success',
    PICTURE_MODE_REQUEST: 'application_data/picture_mode_request',
    PICTURE_MODE_SUCCESS: 'application_data/picture_mode_success',
    EXIT_PICTURE_MODE_REQUEST: 'application_data/exit_picture_mode_request',
    EXIT_PICTURE_MODE_SUCCESS: 'application_data/exit_picture_mode_success',
    SET_FIREBASE_META: 'application_data/set_firebase_meta',
    SET_FIREBASE_DATA: 'application_data/set_firebase_data',
    SET_FIREBASE_SIGNED_IN: 'application_data/set_firebase_signed_in',
    SET_FIREBASE_SIGNED_IN_SUCCESS: 'application_data/set_firebase_signed_in_success',
    TERMINAL_REQUEST: 'application_data/terminal_request',
    SIGNATURE_REQUEST: 'application_data/signature_request',
    SET_SIGNATURE: 'application_data/set_signature',
    CLEAR_SIGNATURE: 'application_data/clear_signature',
    SIGN_REQUEST: 'application_data/sign_request',
    SIGNATURE_SUCCESSFUL: 'application_data/signature_successful',
    ORDER_REQUEST: 'application_data/order_request',
    ORDER_SUCCESS: 'application_data/order_success',
    ORDER_CLEAR: 'application_data/order_clear',
    ORDER_UPLOAD_REQUEST: 'application_data/order_upload_request',
    ORDER_UPLOAD_SUCCESS: 'application_data/order_upload_success',
    PICTURE_SAVE: 'application_data/picture_save',
    PICTURE_REMOVE: 'application_data/picture_remove',
    PICTURE_TOGGLE_SELECTION: 'application_data/picture_toggle_selection',
};

export const pictureToggleSelection = ({key, value}) => ({
    type: Types.PICTURE_TOGGLE_SELECTION,
    payload: {
        key: key,
        value: value,
    }
});

export const pictureSave = ({data}) => ({
    type: Types.PICTURE_SAVE,
    payload: {
        data: data,
    }
});

export const pictureRemove = ({key}) => ({
    type: Types.PICTURE_REMOVE,
    payload: {
        key: key,
    }
})

export const orderUploadRequest = ({id, doAfter}) => ({
    type: Types.ORDER_UPLOAD_REQUEST,
    payload: {
        id: id,
        doAfter: doAfter,
    }
});

export const orderUploadSuccess = ({id}) => ({
    type: Types.ORDER_UPLOAD_SUCCESS,
    payload: {
        id: id,
    }
});

export const orderClear = () => ({
    type: Types.ORDER_CLEAR,
});

// Should be named setOrder
export const orderRequest = ({order: orderData}) => ({
    type: Types.ORDER_REQUEST,
    payload: {
        order: orderData
    },
});

export const orderSuccess = (data) => ({
    type: Types.ORDER_SUCCESS,
    payload: {
        data: data,
    }
});

export const setSignatureSuccessful = (data) => ({
    type: Types.SIGNATURE_SUCCESSFUL,
    payload: {
        data: data,
    }
});

export const clearSignature = () => ({
    type: Types.CLEAR_SIGNATURE,
});

export const signatureRequest = ({identifier}) => ({
    type: Types.SIGNATURE_REQUEST,
    payload: {
        identifier,
    }
});

export const setSignature = (data) => ({
    type: Types.SET_SIGNATURE,
    payload: {
        data: data,
    }
})

export const signRequest = ({identifier, fileData, doAfter}) => ({
    type: Types.SIGN_REQUEST,
    payload: {
        identifier,
        fileData,
        doAfter,
    }
});

export const terminalRequest = () => ({
    type: Types.TERMINAL_REQUEST,
});

export const setFirebaseSignedIn = (signedIn) => ({
    type: Types.SET_FIREBASE_SIGNED_IN,
    payload: {
        signedIn: signedIn,
    }
});

export const setFirebaseSignedInSuccess = (signedIn) => ({
    type: Types.SET_FIREBASE_SIGNED_IN_SUCCESS,
    payload: {
        signedIn: signedIn,
    }
});

export const setFirebaseData = (data) => ({
    type: Types.SET_FIREBASE_DATA,
    payload: {
        data: data,
    }
});

export const setFirebaseMeta = (data) => ({
    type: Types.SET_FIREBASE_META,
    payload: {
        data: data,
    }
});

export const tokenLoginRequest = ({doAfter}) => ({
    type: Types.TOKEN_LOGIN_REQUEST,
    payload: {
        doAfter,
    }
});

export const loginRequest = ({code, doAfter}) => ({
    type: Types.LOGIN_REQUEST,
    payload: {
        code,
        doAfter,
    }
});

export const loginSuccess = () => ({
    type: Types.LOGIN_SUCCESS,
});

export const meRequest = () => ({
    type: Types.ME_REQUEST,
});

export const meSuccess = (data) => ({
    type: Types.ME_SUCCESS,
    payload: {
        data: data,
    }
});

export const logoutRequest = () => ({
    type: Types.LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
    type: Types.LOGOUT_SUCCESS,
});

export const receiveModeRequest = () => ({
    type: Types.RECEIVE_MODE_REQUEST,
});

export const receiveModeSuccess = () => ({
    type: Types.RECEIVE_MODE_SUCCESS,
});

export const exitReceiveModeRequest = ({pin, force = false}) => ({
    type: Types.EXIT_RECEIVE_MODE_REQUEST,
    payload: {
        pin,
        force,
    }
});

export const exitReceiveModeSuccess = () => ({
    type: Types.EXIT_RECEIVE_MODE_SUCCESS,
});

export const pictureModeRequest = () => ({
    type: Types.PICTURE_MODE_REQUEST,
});

export const pictureModeSuccess = () => ({
    type: Types.PICTURE_MODE_SUCCESS,
});

export const exitPictureModeRequest = () => ({
    type: Types.EXIT_PICTURE_MODE_REQUEST,
});

export const exitPictureModeSuccess = () => ({
    type: Types.EXIT_PICTURE_MODE_SUCCESS,
});