import React, {useEffect, useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import DocumentViewer from "../../components/DocumentViewer";
import SignIcon from "@material-ui/icons/BorderColor";
import UnlockIcon from "@material-ui/icons/LockOpen";
import FixablyModal from "../../components/FixablyModal";
import Signature from "../../components/Signature";
import {exitReceiveModeRequest, signRequest} from "../../redux/actions/applicationData";
import CircularProgress from "@material-ui/core/CircularProgress";
import Numpad from "../../components/Numpad";

function ReceiveMode(props) {
    const {
        exitReceiveModeRequest,
        signature,
        firebaseData,
        signRequest,
        signatureSuccessful,
    } = props;

    const [signOpen, setSignOpen] = useState(false);
    const [exitReceiveModeOpen, setExitReceiveModeOpen] = useState(false);
    const [documentsTitle, setDocumentsTitle] = useState(null);

    const handlePinComplete = (pin) => {
        exitReceiveModeRequest({pin});
    }

    useEffect(() => {
        if (signatureSuccessful) {
            return;
        }
        if ((!signature || signature.signed) && signOpen) {
            setSignOpen(false);
        }
        if (signature && signature.name && documentsTitle !== signature.name) {
            setDocumentsTitle(signature.name);
        }
    }, [signature, documentsTitle, signOpen, signatureSuccessful]);

    useEffect(() => {
        if (signatureSuccessful) {
            return;
        }
        if ((!firebaseData || !firebaseData.location) && signOpen) {
            exitReceiveModeRequest({force: true});
        }
    }, [firebaseData, exitReceiveModeRequest, signOpen, signatureSuccessful]);

    const renderDocument = () => {
        return (
            <div style={{flex: 1, height: '100%', width: '100%'}}>
                <div style={{
                    padding: 12,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div style={{flex: 1}}>
                        <DocumentViewer uri={signature.documentFileUrl} pdf={signature.documentFilePdf}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <Button
                            style={{marginTop: 12, marginRight: 12}}
                            variant='outlined'
                            color='default'
                            onClick={() => setExitReceiveModeOpen(true)}
                            endIcon={<UnlockIcon/>}>
                            Exit receive mode
                        </Button>
                        {signature.signed ?
                            <Button
                                style={{marginTop: 12, flex: 1}}
                                variant='outlined'
                                disabled
                                color='primary'
                                onClick={() => {
                                }}
                                endIcon={<CheckIcon/>}>
                                ({signature.name}) Signed by {signature.signer}
                            </Button>
                            :
                            <Button
                                style={{marginTop: 12, flex: 1}}
                                variant='contained'
                                color='secondary'
                                onClick={() => setSignOpen(true)}
                                endIcon={<SignIcon/>}>
                                ({signature.name}) Sign document(s) as {signature.signer}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }

    const renderReceiveMode = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column'
            }}>
                <CircularProgress color="primary"/>
                <br/>
                Ready to receive documents...
                <Button
                    style={{marginTop: 12, marginRight: signature ? 12 : 0}}
                    variant='outlined'
                    color='default'
                    onClick={() => setExitReceiveModeOpen(true)}
                    endIcon={<UnlockIcon/>}>
                    Exit receive mode
                </Button>
            </div>
        );
    }

    const renderSignatureSuccessful = () => {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CheckCircleOutlineRoundedIcon color={'primary'} style={{fontSize: 64, marginRight: 12}}/>
                <Typography color={'primary'} variant={'h3'}>Document(s) signed successfully</Typography>
            </div>
        )
    }

    const onSign = ({signaturePad, doAfter}) => {
        const base64png = signaturePad.toDataURL();
        signRequest({identifier: signature.id, fileData: base64png, doAfter});
    }

    const signer = signature && signature.signer ? signature.signer : '';

    return (
        !signatureSuccessful
            ? <div style={{height: '100%', width: '100%'}}>
                {
                    signature
                        ? renderDocument()
                        : renderReceiveMode()
                }
                <FixablyModal
                    title={`Sign "${documentsTitle}" as ${signer}`}
                    open={signOpen}
                    onClose={() => setSignOpen(false)}>
                    <Signature
                        disclaimer={signature && signature.disclaimer ? signature.disclaimer : null}
                        onSign={onSign}
                    />
                </FixablyModal>
                <Numpad label={'Pin'}
                        type={'password'}
                        titleIcon={<UnlockIcon/>}
                        title={'Exit receive mode'}
                        open={exitReceiveModeOpen}
                        onClose={() => setExitReceiveModeOpen(false)}
                        onDone={(data) => handlePinComplete(data)}
                />
            </div> :
            renderSignatureSuccessful()
    );
}

export default connect(({applicationData}) => ({
    firebaseData: applicationData.firebaseData,
    signature: applicationData.signature,
    signatureSuccessful: applicationData.signatureSuccessful,
}), {exitReceiveModeRequest, signRequest})(ReceiveMode);
