import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    dividerSpacing: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    captionIcon: {
        fontSize: 18,
    },
    negativeHorizontalMargin1: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1)
    },
    headerButton: {
        margin: theme.spacing(1),
        marginTop: '0 !important',
        marginBottom: '0 !important',
    },
    box: {
        padding: theme.spacing(1),
    },
    horizontalBox: {
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        margin: theme.spacing(1),
    },
}));

function DeviceInformation(props) {
    const {
        firebaseData,
    } = props;

    const deviceIdentifier = firebaseData && firebaseData.identifier ? firebaseData.identifier : 'No identifier';
    const deviceName = firebaseData && firebaseData.name ? firebaseData.name : '<Device has no name>';
    const deviceUnlockCode = firebaseData && firebaseData.unlockCode ? firebaseData.unlockCode : 'Unlock code is not set';
    const deviceLocation = firebaseData && firebaseData.location ? firebaseData.location : null;
    const notPairedMessage = '<Device is not paired>';

    const classes = useStyles();
    const subtitle = 'h6';

    return (
        <Container maxWidth={'md'}>
            <Box className={clsx(classes.marginBottom)}>
                <Box className={clsx(classes.horizontalBox)}>
                    <Typography color={firebaseData && firebaseData.name ? 'inherit' : 'secondary'} variant={'h6'} style={{flex: 1, display: 'flex'}}>{deviceName}</Typography>
                </Box>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Typography variant={subtitle}>Device information</Typography>
                    <div><b>Name:</b> <Typography color={firebaseData && firebaseData.name ? 'inherit' : 'secondary'} variant={'inherit'}>{deviceName}</Typography></div>
                    <div><b>Identifier:</b> {deviceIdentifier}</div>
                    <div><b>Receive mode unlock code:</b> {deviceUnlockCode}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    { deviceLocation
                        ?
                        <section>
                            <Typography variant={subtitle}>Paired with location</Typography>
                            <div><b>Name:</b> {deviceLocation.name}</div>
                            <div><b>Address:</b> {deviceLocation.address}, {deviceLocation.zip} {deviceLocation.city}</div>
                        </section>
                        : <Typography variant={subtitle} color={'secondary'}>{notPairedMessage}</Typography>
                    }

                </Grid>
            </Grid>
        </Container>
    );
}

export default connect(({applicationData}) => ({
    firebaseData: applicationData.firebaseData,
}), {
    //
})(DeviceInformation);
