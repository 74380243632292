import api from './index';
import Configuration from "../configuration";

import {getEnvironmentVariables} from "../configuration/environment";

export const me = () => {
    return api.get(`/users/me`);
};

export const tokenRequest = ({code}) => {
    return api.post('/auth/token', {
        code: code,
        grant_type: 'authorization_code',
        client_id: Configuration.api.clientId,
        client_secret: '',
        redirect_uri: getEnvironmentVariables().auth.redirectURL,
    });
};

export const registerTerminal = () => {
    return api.post( `/signature-terminals/register`);
};

export const getTerminal = ({identifier}) => {
    return api.get(`/signature-terminals/${identifier}`);
}

export const getSignature = ({identifier}) => {
    return api.get(`/signatures/${identifier}?inlinePdf=true`);
}

export const sign = ({identifier, fileData}) => {
    return api.post(`/signatures/${identifier}/sign`, {fileData});
}

export const getOrder = ({id}) => {
    return api.get(`/orders/${id}?expand=customer,notes(items)`);
    // return new Promise((resolve, reject) => {
    //    resolve({
    //        data: {
    //            id: id,
    //            contactName: 'Joakim Sandqvist',
    //            customerName: 'J.S.A. Sandqvist Oy',
    //            issue: 'Broken screen',
    //        },
    //    });
    // });
}

export const orderUpload = ({id, fileData}) => {
    return api.post(`/orders/${id}/upload`, {fileData});
}