import axios from 'axios';
import Configuration from "../configuration";
import {logoutSuccess} from "../redux/actions/applicationData";
import {store} from "../services/StoreService";
import ToastService from "../services/ToastService";
import {loadRefreshToken, loadToken} from "../services/TokenService";
import {refreshAccessToken} from "../redux/sagas/applicationData";

const instance = axios.create({
    baseURL: Configuration.api.baseURL,
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, async function (error) {
    if (!error && !error.response) {
        console.log(error);
        return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && loadRefreshToken()) {
        originalRequest._retry = true;
        await refreshAccessToken();
        const newToken = loadToken();
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return instance(originalRequest);
    }

    const configUrl = error.response.config.url;

    // Do something with response error
    console.log(error.response);
    switch (error.response.status) {
        case 400:
            if (configUrl === '/auth/token') {
                ToastService.warning('Invalid credentials', '');
            }
            break;
        case 401:
            if (configUrl === '/auth/token') {
                ToastService.warning('Invalid credentials', '');
            } else {
                ToastService.warning('Your session has expired.', '');
            }
            store.dispatch(logoutSuccess());

            break;
        case 500:
            // TOAST 500
            // console.error(500);
            break;
        case 405:
            // console.log(error.response.status, error.response.data);
            ToastService.warning(error.response.data.error, '');
            break;
        case 406:
            // console.log(error.response.status, error.response.data);
            ToastService.info(error.response.data.error, '');
            break;
        default:
            ToastService.error('Something went wrong', '');
            break;
    }

    console.error(error.response.status);
    return Promise.reject(error);
});

export default instance;
