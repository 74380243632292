import {Types} from "../actions/UI";

const INITIAL_STATE = {
    navigationDrawer: {
        open: false,
    },
};

export default function UI(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.NAVIGATION_DRAWER_TOGGLE: {
            return {
                ...state,
                navigationDrawer: {
                    ...state.navigationDrawer,
                    open: !state.navigationDrawer.open,
                }
            }
        }
        default:
            return state;
    }
}
