import UISagas from './UI';
import applicationDataSagas from './applicationData';
import {all} from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        ...UISagas,
        ...applicationDataSagas,
    ]);
}
