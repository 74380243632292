import firebase from 'firebase';

const DEVICE_IDENTIFIER = 'device_identifier';
const LOCATION = 'location';

export class FirebaseService {
    static async signInAnonymously() {
        await firebase.app().auth().signInAnonymously();
    }

    static instance() {
        return firebase;
    }
}

export const saveDeviceIdentifier = (identifier) => {
    localStorage.setItem(DEVICE_IDENTIFIER, identifier);
}

export const loadDeviceIdentifier = () => {
    return localStorage.getItem(DEVICE_IDENTIFIER);
}

export const clearDeviceIdentifier = () => {
    localStorage.removeItem(DEVICE_IDENTIFIER);
}

export const saveLocation = (location) => {
    localStorage.setItem(LOCATION, location);
}

export const loadLocation = () => {
    return localStorage.getItem(LOCATION);
}

export const clearLocation = () => {
    localStorage.removeItem(LOCATION);
}
