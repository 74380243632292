import React, {useEffect, useRef, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function DocumentViewer(props) {
    const {
        uri,
        pdf,
    } = props;

    const documentWrapperRef = useRef(null);

    const documentDefaultStyle = {
        objectFit: 'contain',
        height: '0',
        width: '0',
    };

    const [documentStyle, setDocumentStyle] = useState(documentDefaultStyle);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });

            setDocumentStyle(documentDefaultStyle);

            if (!documentWrapperRef.current) {
                return;
            }

            const newDocumentStyle = {
                height: documentWrapperRef.current.clientHeight,
                width: documentWrapperRef.current.clientWidth,
            };

            setDocumentStyle(newDocumentStyle);
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [documentWrapperRef]);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [renderSuccess, setRenderSuccess] = useState(false);
    const [renderIncrement, setRenderIncrement] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setRenderSuccess(true);
        setNumPages(numPages);
    }

    setTimeout(() => {
        if (!renderSuccess) {
            setRenderIncrement(renderIncrement + 1);
        }
    }, 1000);

    const [pages, setPages] = useState([]);
    useEffect(() => {
        const tmpPages = [];
        for (var i = 0; i < numPages; i++) {
            tmpPages.push(<Page key={'page_' + i + 1} pageNumber={i+1} width={documentStyle.width} height={documentStyle.height}/>);
        }
        setPages(tmpPages);

    }, [numPages]);

    const document = () => {
        // if dimensions change, the KEY will force a rerender on the component and the zoom of the document will be recalculated.
        // Probably will need to convert PDF to IMG as pdf preview does not work on Android Chrome. Not sure about iOS
        return <div ref={documentWrapperRef} key={dimensions.height + '_' + dimensions.width + '_' + renderIncrement + '_' + numPages}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}>
            <div style={{
                width: documentStyle.width,
                height: documentStyle.height,
                overflowY: 'scroll',
                overflowX: 'hidden'
            }}>
                <Document
                    style={documentStyle}
                    file={pdf}
                    onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                    {pages}
                </Document>
            </div>
        </div>
    }

    return document();
}