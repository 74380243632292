import packageJson from '../../package.json';

const NAME_AND_VERSION_KEY = 'nameAndVersion';
export const CURRENT_VERSION = packageJson.version;
export const NAME_AND_VERSION = `${packageJson.name} ${CURRENT_VERSION}`;

function PWAUpdater() {
    const lastKnownVersion = localStorage.getItem(NAME_AND_VERSION_KEY);
    const currentVersion = NAME_AND_VERSION;
    localStorage.setItem(NAME_AND_VERSION_KEY, currentVersion);

    if (currentVersion !== lastKnownVersion) {
        window.location.reload();
    }

    return null;
}

export default PWAUpdater;