import React, {useRef} from 'react';
import {connect} from "react-redux";
import {pictureSave} from "../redux/actions/applicationData";
import {Button} from "@material-ui/core";

function ImageUpload(props) {
    const {
        order,
        pictureSave,
    } = props;

    const saveImage = (base64image) => {
        pictureSave({data: base64image});
    }

    const handleOnUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            saveImage(reader.result);
        }
    }

    const fileUpload = useRef();

    if (order)
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Button
                    variant={'contained'}
                    component={'label'}
                    onClick={() => {fileUpload.current.click()}}
                >
                    Upload picture
                </Button>
                <input
                    ref={fileUpload}
                    accept="image/*"
                    id="contained-button-file"
                    hidden
                    capture="environment"
                    type="file"
                    onChange={(e) => handleOnUpload(e)}
                />
            </div>
        );

    return (
        <div>No order selected</div>
    );
}

export default connect(({applicationData}) => ({
    order: applicationData.pictureModeData.order,
}), {pictureSave})(ImageUpload);