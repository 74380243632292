import {connect} from "react-redux";
import {FirebaseDatabaseProvider} from "@react-firebase/database";
import React from "react";
import firebase from "firebase";
import {FirebaseAuthConsumer, FirebaseAuthProvider} from "@react-firebase/auth";
import FirebaseSignatureReducerUpdater from "./FirebaseSignatureReducerUpdater";
import {setFirebaseSignedIn} from "../redux/actions/applicationData";
import {FirebaseService} from "./FirebaseService";

function FirebaseHandler(props) {
    const {
        firebaseData,
        setFirebaseSignedIn,
    } = props;

    const renderFirebase = () => {
        return (
            <section>
                <FirebaseAuthProvider {...firebaseData.firebase} firebase={firebase}>
                    <FirebaseAuthConsumer>
                        {({isSignedIn}) => {
                            setTimeout(() => {
                                setFirebaseSignedIn(isSignedIn);
                            });
                            if (isSignedIn === true) {
                                // Continue to application only if connected to firebase
                                return (<FirebaseDatabaseProvider {...firebaseData.firebase} firebase={firebase}>
                                    <FirebaseSignatureReducerUpdater/>
                                </FirebaseDatabaseProvider>)
                            } else {
                                // Firebase not connected
                                // Realtime db only promises an uptime of 99.95% percent as opposed to the firestore which promises 99.999%
                                // Realtime db was chosen because the existing payment terminal system uses realtime db.

                                // connecting to fire base
                                FirebaseService.signInAnonymously().finally(() => {});

                                return null;
                            }
                        }}
                    </FirebaseAuthConsumer>
                </FirebaseAuthProvider>
            </section>
        );
    }

    const renderContent = () => {
        // firebase config is fetched while fetching the terminal
        if (firebaseData?.firebase) {
            return renderFirebase();
        }

        return null;
    }

    return renderContent();
}

export default connect(({applicationData}) => ({
    firebaseData: applicationData.firebaseData,
}), {
    setFirebaseSignedIn,
})(FirebaseHandler);