import {connect} from "react-redux";
import {FirebaseDatabaseNode} from "@react-firebase/database";
import React from "react";
import {clearSignature, signatureRequest, terminalRequest} from "../redux/actions/applicationData";

function FirebaseSignatureReducerUpdater(props) {

    const {
        firebaseMeta,
        terminalRequest,
        signatureRequest,
        clearSignature,
        signatureSuccessful,
    } = props;

    if (!firebaseMeta || signatureSuccessful) return null;

    // The database node starts a request right before the library component ceases to exist (?)
    // Making the request on next iteration fixes the issue
    const toNextIteration = (fn) => {
        setTimeout(() => {
            fn();
        });
    };

    if (!firebaseMeta.location) {
        return null;
    }

    return (
        <section>
            <FirebaseDatabaseNode path={firebaseMeta.location + '/signatureId'}>
                {data => {
                    if (data.value !== 0 && data.value) {
                        // get signature
                        toNextIteration(() => signatureRequest({identifier: data.value}));

                    } else {
                        // clear signature
                        toNextIteration(() => clearSignature());
                    }
                    return null;
                }}
            </FirebaseDatabaseNode>
            <FirebaseDatabaseNode path={firebaseMeta.location + '/updatedAt'}>
                {data => {
                    if (data.value) {
                        toNextIteration(() => terminalRequest());
                    }
                    return null;
                }}
            </FirebaseDatabaseNode>
        </section>
    );
}

export default connect(({applicationData}) => ({
    firebaseMeta: applicationData.firebaseMeta,
    signatureSuccessful: applicationData.signatureSuccessful,
}), {
    terminalRequest,
    signatureRequest,
    clearSignature,
})(FirebaseSignatureReducerUpdater);