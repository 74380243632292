import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/HelpOutline';

import "./SideDrawer.css";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {navigationDrawerToggle} from "../../redux/actions/UI";

function SideDrawer(props) {
  const {
    navigationDrawerToggle,
    drawerOpen
  } = props;

  const useStyles = makeStyles({
    list: {
      width: 250,
    },
  });

  const toggleDrawer = (func) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (!func) return;
    func();
  };

  const SideList = ({children}) => {
    const classes = useStyles();

    return (
      <div
        className={classes.list}
        style={{display: 'flex', flexDirection: 'column', height: '100vh'}}
        role="presentation"
        onClick={toggleDrawer(() => navigationDrawerToggle())}
        onKeyDown={toggleDrawer(() => navigationDrawerToggle())}
      >{children}</div>
    );
  };

  const sideListItem = (to, key, text, icon) => {
    return to ?
      (
        <Link to={to}>
          <ListItem button key={key}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        </Link>
      ) :
      (
        <ListItem button key={key}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text}/>
        </ListItem>
      )
  };

  return (
    <Drawer open={drawerOpen} onClose={() => navigationDrawerToggle()}>
      <SideList toggleDrawer={toggleDrawer}>
        <div style={{flex: 1}}>
          <List>
            {sideListItem('/dashboard', 'deviceInformation', 'Device information', <DashboardIcon/>)}
          </List>
        </div>
        <div>
          <Divider/>
          <List>
            {sideListItem(false, 'support', 'Support', <HelpIcon/>)}
          </List>
        </div>
      </SideList>
    </Drawer>
  );
}

export default connect(({UI}) => ({drawerOpen: UI.navigationDrawer.open}), {
  navigationDrawerToggle
})(SideDrawer);
