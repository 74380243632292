import React, {useEffect, useState} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";
import {styles} from "../theme/theme";
import {connect} from "react-redux";
import {orderUploadRequest, pictureRemove, pictureToggleSelection} from "../redux/actions/applicationData";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";

function SelectImages(props) {
    const {
        order,
        orderUploadRequest,
        pictureRemove,
        pictureToggleSelection,
        pictures,
    } = props;

    // const [images, setImages] = useState([]);
    // const [selectedItems, setSelectedItems] = useState({});

    useEffect(() => {
        if (!order) {
            // PictureService.removeLocalPictures();
            pictureRemove();
        }
        // setImages(PictureService.findLocalPictures());
        // selectAllPictures();
        // setSelectedItems({});
    }, [order])

    const toggleItemSelection = (item) => {
        pictureToggleSelection({key: item.key});
        // let newItems = {...selectedItems};
        // if (newItems[item.key]) {
        //     delete (newItems[item.key]);
        //     setSelectedItems(newItems);
        // } else {
        //     newItems[item.key] = item;
        //     setSelectedItems(newItems);
        // }
    }


    // const selectAllPictures = () => {
    //     let selection = {};
    //     PictureService.findLocalPictures().forEach((item) => {
    //         selection[item.key] = item;
    //     });
    //     setSelectedItems(selection);
    // }

    const renderSelectButton = (item) => {
        const image = <div style={{
            backgroundImage: `url(${item.data})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '80px',
            height: '80px',
            borderRadius: 6,
        }}/>

        const color = item.selected ? styles.colors.fixablyPrimary : '#eee';

        return <div key={item.key + `${item.selected}`} style={{
            cursor: 'pointer',
            display: 'inline-block',
            borderRadius: 6,
            marginBottom: 6,
            marginRight: 6,
            backgroundColor: item.selected ? styles.colors.fixablyPrimary : '#eee',
            border: 'solid 2px ' + color,
        }} onClick={() => {
            toggleItemSelection(item);
        }}>
            {image}
        </div>
    }

    const renderSelectButtons = (images) => {
        return Object.keys(pictures).map((key) => {
           return renderSelectButton(pictures[key]);
        });
    }

    const discardImages = () => {
        // const pictureKeyArray = Object.keys(selectedItems);
        // removeLocalPictures(pictureKeyArray);
        // setImages(PictureService.findLocalPictures());
        // setSelectedItems({});

        Object.keys(pictures).forEach((key) => {
            if (pictures[key].selected) {
                pictureRemove({key: key});
            }
        });
    }

    const [selectionAmount, setSelectionAmount] = useState(0);

    useEffect(() => {
        let selections = 0;
        Object.keys(pictures).forEach((key) => {
            if (pictures[key].selected) {
                selections++;
            }
        });

        if (selections === Object.keys(pictures).length && selectAll === false) {
            setSelectAll(true);
        } else if (selections === 0 && selectAll === true) {
            setSelectAll(false);
        }

       setSelectionAmount(selections);
    }, [pictures]);

    const [selectAll, setSelectAll] = useState(false);

    const selectAllClicked = () => {
        setSelectAll(!selectAll);
        Object.keys(pictures).forEach((key) => {
            pictureToggleSelection({key: key, value: !selectAll});
        });
    }

    const [uploadLoading, setUploadLoading] = useState(false);

    const onUploadClicked = () => {
        setUploadLoading(true);
        orderUploadRequest({id: order.id, doAfter: () => setUploadLoading(false)});
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%'}}>
            <div style={{width: '100%'}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={() => selectAllClicked()}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Toggle select All"
                />
                <IconButton style={{float: 'right'}} disabled={!selectionAmount}
                            color="secondary" aria-label="Discard picture"
                            onClick={() => discardImages()}>
                    <DeleteForeverIcon style={{fontSize: 24}}/>
                </IconButton>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                <div style={{flex: 1}}>
                    {renderSelectButtons()}
                </div>
                <Button disabled={!selectionAmount || uploadLoading} variant="contained" color="primary"
                        onClick={() => {
                            onUploadClicked();
                        }}>
                    {!uploadLoading ? 'Upload (' + selectionAmount + ')' : <CircularProgress size={24}/> }
                </Button>
            </div>
        </div>
    );
}

export default connect(({applicationData}) => ({
    order: applicationData.pictureModeData.order,
    pictures: applicationData.pictureModeData.pictures,
}), {orderUploadRequest, pictureRemove, pictureToggleSelection})(SelectImages);