import React, {useEffect, useState} from 'react';
import "./Main.css";
import SideDrawer from "./SideDrawer/SideDrawer";
import ToastService from "../services/ToastService";
import {Route, Switch, withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import HeaderNav from "./HeaderNav/HeaderNav";
import {Box} from "@material-ui/core";
import DeviceInformation from "../pages/DeviceInformation/DeviceInformation";
import {HistoryWrapper} from "../services/HistoryWrapper";
import Login from "../pages/Login/Login";
import {AUTH_STATE_LOGGED_IN, AUTH_STATE_LOGGED_OUT} from "../redux/reducers/applicationData";
import {connect} from "react-redux";
import ReceiveMode from "../pages/ReceiveMode/ReceiveMode";
import {tokenLoginRequest} from "../redux/actions/applicationData";
import CircularProgress from "@material-ui/core/CircularProgress";
import PictureMode from "../pages/PictureMode/PictureMode";

function Main(props) {

    const {
        authState,
        receiveMode,
        pictureMode,
        tokenLoginRequest,
        user,
    } = props;

    HistoryWrapper.init(props.history);

    // TODO: refactor this to own service
    if (authState === AUTH_STATE_LOGGED_OUT && props.location.pathname.split('/')[1] !== 'login') {
        // Auth state is LOGGED_OUT and we are not in /login route, redirect to /login
        setTimeout(() => {
            HistoryWrapper.history.push('/login');
        });
    } else if (authState === AUTH_STATE_LOGGED_IN && props.location.pathname.split('/')[1] !== 'receiveMode' && receiveMode) {
        // receiveMode goes to true when we are not in /receiveMode route, redirect to /receiveMode
        setTimeout(() => {
            HistoryWrapper.history.push('/receiveMode');
        });
    } else if (authState === AUTH_STATE_LOGGED_IN && props.location.pathname.split('/')[1] !== 'pictureMode' && pictureMode) {
        // receiveMode goes to true when we are not in /receiveMode route, redirect to /receiveMode
        setTimeout(() => {
            HistoryWrapper.history.push('/pictureMode');
        });
    } else if ((props.location.pathname.split('/')[1] === 'receiveMode' && !receiveMode)
    || (props.location.pathname.split('/')[1] === 'pictureMode' && !pictureMode)) {
        // receiveMode goes to false when we are in /receiveMode route, redirect to /dashboard
        setTimeout(() => {
            HistoryWrapper.history.push('/dashboard');
        });
    }
    // TODO: END OF TODO
    const [tokenRequestLoading, setTokenRequestLoading] = useState(true);

    function endLoggingIn() {
        setTokenRequestLoading(false);
    }

    useEffect(() => {
        tokenLoginRequest({doAfter: () => endLoggingIn()})
    }, [tokenLoginRequest]);

    const renderPictureModeRoute = () => {
        const features = user?.tenant?.subscription?.features;
        if (!features?.length) {
            return null;
        }
        const paperlessAdvancedEnabled = features.find((it) => {
            return it === 'paperlessAdvanced';
        });

        if (paperlessAdvancedEnabled) {
            return <Route exact path="/pictureMode" component={PictureMode} key={'pictureMode'}/>;
        }

        return null;
    }

    return (
        <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ToastService.ToastContainer/>
            {tokenRequestLoading ? <CircularProgress color="primary"/> :
                <Switch>
                    <Route exact={true} path="/login" component={Login} key={'login'}/>
                    <Route exact path="/receiveMode" component={ReceiveMode} key={'receiveMode'}/>
                    { renderPictureModeRoute() }
                    <Route path="/dashboard" component={() => {
                        return (
                            <section style={{position: 'relative', top: 32, height: 'calc(100% - 128px)', width: 'calc(100% - 64px)'}}>
                                <HeaderNav/>
                                <SideDrawer/>
                                <Box style={{height: '100%'}}>
                                    <Paper style={{height: '100%', padding: 12}}>
                                        <Switch>
                                            <Route exact path="/dashboard" component={DeviceInformation}
                                                   key={'deviceInformation'}/>
                                            <Route component={() => <div>404</div>}/>
                                        </Switch>
                                    </Paper>
                                </Box>
                            </section>
                        );
                    }}/>
                    <Route component={() => <div>404</div>}/>
                </Switch>
            }
        </Box>
    );
}

export default connect(({applicationData}) => ({
    user: applicationData.user,
    authState: applicationData.auth.state,
    receiveMode: applicationData.receiveMode,
    pictureMode: applicationData.pictureMode
}), {
    tokenLoginRequest
})(withRouter(Main));
