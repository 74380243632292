import {fork, put} from 'redux-saga/effects';
import * as actions from '../actions/UI';
import {take} from "@redux-saga/core/effects";

function* navigationDrawerToggle(action) {
    yield put(actions.navigationDrawerToggle());
}

function* watchNavigationDrawerToggle() {
    yield take(actions.Types.NAVIGATION_DRAWER_TOGGLE, navigationDrawerToggle);
}

const UISagas = [
    fork(watchNavigationDrawerToggle),
];

export default UISagas;